import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { koolioSpaceTourActions } from "../../redux/slice/KoolioSpaceTourSlice";
import { Koolioai, _config } from "../../utils/cognitoAuth";
import axios from "axios";
import topbar from "topbar";
import "../../css/dark/ProductTours.css";

const CustomTooltip = ({
  index,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  isLastStep,
  totalSteps,
  goToNextStep,
  goToPreviousStep,
}) => {
  return (
    <div {...tooltipProps} className="tooltip-container">
      {step.title && <div className="tooltip-title">{step.title}</div>}

      <div className="tooltip-content">{step.content}</div>
      <div className="tooltip-footer">
        <button {...skipProps} className="tooltip-skip-button">
          Skip
        </button>
        {index > 0 && (
          <button
            {...backProps}
            className="tooltip-back-button"
            onClick={goToPreviousStep}
          >
            Back
          </button>
        )}
        <button
          {...primaryProps}
          className="tooltip-next-button"
          onClick={goToNextStep}
        >
          {isLastStep ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  );
};

const KoolioSpaceTour = () => {
  const dispatch = useDispatch();
  const runKoolioSpaceTour = useSelector(
    (state) => state.koolioSpaceTour.runKoolioSpaceTour,
  );
  const [stepIndex, setStepIndex] = useState(0);
  const [autoAdvanceTimeout, setAutoAdvanceTimeout] = useState(null);

  const steps = [
    {
      target: "#landing-screen",
      title: "Welcome to the koolio workspace!",
      content:
        "I'll be your tour guide, walking you through various features...",
      placement: "center",
      disableBeacon: true,
    },
    {
      target: "#record-btn",
      title: "Record Project",
      content: "To record a new project, click on the record icon.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#upload-btn",
      title: "Upload Project",
      content: "To upload a new project, click on the upload icon.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#koolio-project",
      title: "Project Section",
      content:
        "Your projects are listed here. Hover over an audio file to view or delete it.",
      placement: "top",
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    const fetchTourGuideData = async () => {
      try {
        const Tour_name = "landing_page_default";
        const localStorageKey = `${Koolioai.username}${Tour_name}`;

        if (localStorage.getItem(localStorageKey) === "true") return;

        setTimeout(async () => {
          const tokens = await Promise.all([
            Koolioai.authToken(),
            Koolioai.accessToken(),
          ]);

          const response = await axios.get(
            _config.api + "/demo-tour-guide-shown",
            {
              headers: {
                Authorization: `${tokens[0]}`,
                AccessToken: `${tokens[1]}`,
              },
            },
          );

          if (response.data.demo_tour_guide.landing_page_default === true) {
            localStorage.setItem(localStorageKey, "true");
          } else {
            const observer = new MutationObserver((mutations, obs) => {
              const allRendered = steps.every((step) =>
                document.querySelector(step.target),
              );

              if (allRendered) {
                obs.disconnect(); // Stop observing once elements are found
                // Start the tour and mark it as completed
                dispatch(koolioSpaceTourActions.startKoolioSpaceTour());
                // Make the POST request to update the status
                axios
                  .post(
                    _config.api + "/demo-tour-guide-shown",
                    { landing_page_default: true },
                    {
                      headers: {
                        Authorization: `${tokens[0]}`,
                        AccessToken: `${tokens[1]}`,
                      },
                    },
                  )
                  .then(() => {
                    localStorage.setItem(localStorageKey, "true"); // Mark as completed in localStorage
                  })
                  .catch((error) =>
                    console.error("Error updating tour guide data:", error),
                  );
              }
            });
            // Start observing the document body for changes
            observer.observe(document.body, {
              childList: true,
              subtree: true,
            });
          }
        }, 5000);
      } catch (error) {
        console.error("Error fetching or updating tour guide data:", error);
      }
    };

    fetchTourGuideData();
  }, [dispatch]);

  useEffect(() => {
    // Clear any ongoing timeouts or intervals
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);

    // Hide the top bar on the last step
    if (stepIndex === steps.length - 1) {
      topbar.hide();
      return; // Exit early to avoid setting up progress on the last step
    }

    if (runKoolioSpaceTour) {
      topbar.show(); // Show the topbar
      topbar.progress("0"); // Reset progress

      let progress = 0;
      const interval = 32; // Milliseconds per increment
      const increment = 0.01; // Progress increment per interval
      let progressInterval;

      const startProgressBar = () => {
        progressInterval = setInterval(() => {
          progress += increment;
          if (progress >= 1) {
            clearInterval(progressInterval);
            topbar.progress("1"); // Ensure it ends at full
          } else {
            topbar.progress(progress.toString());
          }
        }, interval);
      };

      // Start the progress bar
      startProgressBar();

      // Advance to the next step after 4 seconds
      const timeout = setTimeout(() => {
        clearInterval(progressInterval); // Stop progress bar updates
        setStepIndex((prevIndex) => prevIndex + 1);
      }, 8000);

      setAutoAdvanceTimeout(timeout);

      return () => {
        clearTimeout(timeout);
        clearInterval(progressInterval);
      };
    }
  }, [runKoolioSpaceTour, stepIndex]);

  const handleTourEnd = () => {
    topbar.hide(); // Dispose the topbar
    dispatch(koolioSpaceTourActions.stopKoolioSpaceTour());
    setStepIndex(0);
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);
  };

  const goToNextStep = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex((prevIndex) => prevIndex + 1);
    }
    if (stepIndex === steps.length - 1) {
      handleTourEnd(); // Handle last step
    }
  };

  const goToPreviousStep = () => {
    if (stepIndex > 0) {
      setStepIndex((prevIndex) => prevIndex - 1);
    }
    if (autoAdvanceTimeout) {
      clearTimeout(autoAdvanceTimeout);
      topbar.progress("0");
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      disableOverlayClose
      disableScrolling
      hideCloseButton
      showSkipButton={true}
      showProgress={true}
      scrollToFirstStep={true}
      run={runKoolioSpaceTour}
      stepIndex={stepIndex}
      callback={(data) => {
        if (data.status === "finished" || data.status === "skipped") {
          handleTourEnd();
        }
      }}
      styles={{
        options: {
          zIndex: 10000,
          overlayColor: "rgba(0,0, 0, 0.8)",
          arrowColor: "#C4C4C4",
        },
      }}
      tooltipComponent={(props) => (
        <CustomTooltip
          {...props}
          totalSteps={steps.length}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
    />
  );
};

export default KoolioSpaceTour;
