import React from "react";
import Joyride from "react-joyride";
import store from "../../redux/Store";
import "../../css/dark/ProductTours.css";
import { useDispatch, useSelector } from "react-redux";
import { transcriptDrivenTourActions } from "../../redux/slice/TranscriptDrivenTourSlice";
import { useEffect, useState } from "react";
import { Koolioai, _config } from "../../utils/cognitoAuth";
import axios from "axios";
import topbar from "topbar";

// Custom Tooltip Component with Skip Button and Step Title
const CustomTooltip = ({
  index,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  isLastStep,
  totalSteps,
  goToNextStep,
  goToPreviousStep,
}) => {
  return (
    <div {...tooltipProps} className="tooltip-container">
      {step.title && <div className="tooltip-title">{step.title}</div>}

      <div className="tooltip-content">{step.content}</div>
      <div className="tooltip-footer">
        <button {...skipProps} className="tooltip-skip-button">
          Skip
        </button>
        {index > 0 && (
          <button
            {...backProps}
            className="tooltip-back-button"
            onClick={goToPreviousStep}
          >
            Back
          </button>
        )}
        <button
          {...primaryProps}
          className="tooltip-next-button"
          onClick={goToNextStep}
        >
          {isLastStep ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  );
};

// Tour Component
const TranscriptDrivenTour = (props) => {
  const dispatch = useDispatch();
  const runTranscriptDrivenTour = useSelector(
    (state) => state.transcriptDrivenTour.runTranscriptDrivenTour,
  );
  const [stepIndex, setStepIndex] = useState(0);
  const [autoAdvanceTimeout, setAutoAdvanceTimeout] = useState(null);

  const steps = [
    {
      target: "#wrapper",
      title: "Transcript Driven Mode",
      content:
        "Your hub for comprehensive audio transcripts, thoughtfully organized by speakers. Here You can conveniently edit your projects using transcripts.",
      placement: "center",
      disableBeacon: true,
    },
    {
      target: "#playlist-transcript",
      title: "Transcript Panel",
      content:
        "You will find the complete audio transcript, organized by speakers, right here. Drag over transcript to select multiple words at once.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: "#input-search-transcript-workspace",
      title: `Search Panel`,
      content:
        "Enter a keyword to search within the extensive Transcript panel and explore related words to enhance your experience.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#transcriptIcons-workspace",
      title: "Selection Buttons",
      content:
        "After entering a word in the search panel, use the arrow button to navigate through instances of the same word at different positions. Alternatively, utilize the double-arrow button to select all occurrences of the same word simultaneously.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#toggle-t-spk",
      title: "Hide Speaker",
      content:
        "Click on this to aggregate all transcripts into one cohesive document, eliminating the speaker-wise distinctions.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#exportTranscriptIcon",
      title: "Export Transcript",
      content:
        "Click on this button to access an array of export options for your transcript, providing you with a versatile choices to tailor your export experience.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#printTranscriptIcon",
      title: "Print Transcript",
      content: "Click on this to print the entire transcript in pdf format.",
      placement: "top",
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    const fetchTourGuideData = async () => {
      try {
        const Tour_name = "transcript_tour"; // Set the current tour name
        const localStorageKey = `${Koolioai.username}${Tour_name}`; // Generate the key

        if (localStorage.getItem(localStorageKey) === "true") {
          return;
        }

        // Fetch tokens
        const tokens = await Promise.all([
          Koolioai.authToken(),
          Koolioai.accessToken(),
        ]);

        // Make the GET request
        const response = await axios.get(
          _config.api + "/demo-tour-guide-shown",
          {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          },
        );

        // Check the `transcript_tour` status
        if (response.data.demo_tour_guide.transcript_tour === true) {
          localStorage.setItem(localStorageKey, "true");
        } else {
          if (props.isChecked === true) {
            // Start the tour and mark it as completed
            dispatch(transcriptDrivenTourActions.startTranscriptDrivenTour());

            // Make the POST request to update the status
            axios
              .post(
                _config.api + "/demo-tour-guide-shown",
                { transcript_tour: true },
                {
                  headers: {
                    Authorization: `${tokens[0]}`,
                    AccessToken: `${tokens[1]}`,
                  },
                },
              )
              .then(() => {
                localStorage.setItem(localStorageKey, "true"); // Mark as completed in localStorage
              })
              .catch((error) =>
                console.error("Error updating tour guide data:", error),
              );
          }
        }
      } catch (error) {
        console.error("Error fetching or updating tour guide data:", error);
      }
    };

    fetchTourGuideData();
  }, [dispatch, props.isChecked]);

  useEffect(() => {
    // Clear any ongoing timeouts or intervals
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);

    // Hide the top bar on the last step
    if (stepIndex === steps.length - 1) {
      topbar.hide();
      return; // Exit early to avoid setting up progress on the last step
    }

    if (runTranscriptDrivenTour) {
      topbar.show(); // Show the topbar
      topbar.progress("0"); // Reset progress

      let progress = 0;
      const interval = 32; // Milliseconds per increment
      const increment = 0.01; // Progress increment per interval
      let progressInterval;

      const startProgressBar = () => {
        progressInterval = setInterval(() => {
          progress += increment;
          if (progress >= 1) {
            clearInterval(progressInterval);
            topbar.progress("1"); // Ensure it ends at full
          } else {
            topbar.progress(progress.toString());
          }
        }, interval);
      };

      // Start the progress bar
      startProgressBar();

      // Advance to the next step after 4 seconds
      const timeout = setTimeout(() => {
        clearInterval(progressInterval); // Stop progress bar updates
        setStepIndex((prevIndex) => prevIndex + 1);
      }, 8000);

      setAutoAdvanceTimeout(timeout);

      return () => {
        clearTimeout(timeout);
        clearInterval(progressInterval);
      };
    }
  }, [runTranscriptDrivenTour, stepIndex]);

  // Reset the tour state after it has been run
  const handleTourEnd = () => {
    topbar.hide(); // Dispose the topbar
    dispatch(transcriptDrivenTourActions.stopTranscriptDrivenTour());
    setStepIndex(0);
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);
  };

  const goToNextStep = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex((prevIndex) => prevIndex + 1);
    }
    if (stepIndex === steps.length - 1) {
      handleTourEnd(); // Handle last step manually
    }
  };

  const goToPreviousStep = () => {
    if (stepIndex > 0) {
      setStepIndex((prevIndex) => prevIndex - 1);
    }
    if (autoAdvanceTimeout) {
      clearTimeout(autoAdvanceTimeout);
      topbar.progress("0");
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      disableOverlayClose
      disableScrolling
      hideCloseButton
      showSkipButton={true} // Enable skip button
      showProgress={true} // Enable step progress
      scrollToFirstStep={true}
      run={runTranscriptDrivenTour}
      stepIndex={stepIndex}
      callback={(data) => {
        if (data.status === "finished" || data.status === "skipped") {
          handleTourEnd(); // Stop tour when it's done
        }
      }}
      styles={{
        options: {
          zIndex: 10000,
          overlayColor: "rgba(24,24,24,1)",
          arrowColor: "#C4C4C4",
        },
      }}
      tooltipComponent={(props) => (
        <CustomTooltip
          {...props}
          totalSteps={steps.length}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
    />
  );
};

export default TranscriptDrivenTour;
