import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    triggerChatBotValue: false,
};

const chatBotValueSlice = createSlice({
  name: "chatBot",
  initialState,
  reducers: {
    updateChatBotShowValue(state, action){
        console.log(action.payload)
      state.triggerChatBotValue = action.payload.currentValue;
    },
  },
});

export const  chatBotValueSliceActions  = chatBotValueSlice.actions;

export default chatBotValueSlice.reducer;
