import { configureStore } from "@reduxjs/toolkit";

//calling different slices into store object
import copyBuffer from "./slice/copyBuffer";
import fileSelectedForWorkspaceSlice from "./slice/FileSelectedForWorkspaceSlice";
import recordSliceReducer from "./slice/RecordSlice";
import recordspaceBtnTriggerSliceReducer from "./slice/RecordspaceBtnTriggerSlice";
import genAISliceReducer from "./slice/GenAiWebsocketDataSlice";
import clickedRegionReducer from "./slice/ClickedRegionSlice";
import projectDataSliceReducer from "./slice/ProjectDataSlice";
import multiSelectWordSliceReducer from "./slice/MultiSelectWordList";
import multiselectSliceReducer from "./slice/MultiselectSlice";
import operationListSliceReducer from "./slice/OperationListSlice";
import closeExportToPodcastApiSliceReducer from "./slice/CloseExportToPodcastApiSlice";
import initiatePopUpForEpisodeInfoSliceReducer from "./slice/InitiatePopUpForEpisodeInfoSlice";
import ExportOptionsSliceReducer from "./slice/ExportOptionsSlice";
import SelectedApiProviderSliceReducer from "./slice/SelectedApiProviderSlice";
import NotificationsDetailsSliceReducer from "./slice/NotificationsDetailsSlice";
import NotificationUpdateModalSliceReducer from "./slice/NotificationUpdateModalSlice";
import ScreenReloadSliceReducer from "./slice/ScreenReloadSlice";
import ViewEditModeSliceReducer from "./slice/ViewEditModeSlice";
import ProjectTitleSliceReducer from "./slice/ProjectTitleSlice";
import copiedAnnotationReducer from "./slice/copiedAnnotation";
import UndoRedoSliceReducer from "./slice/UndoRedoSlice";
import soloReducer from "./slice/soloenable.js";
import uploadprogreestatusreducer from "./slice/uploadprogreestatus";
import wavesurferobjsliceReducer from "./slice/wavesurferobj";
import wavesurferobjSFXsliceReducer from "./slice/wavesurferObjSFX";
import wavesurferobjGlobalsliceReducer from "./slice/wavesurferObjGlobal";
import frontendApplySliceReducer from "./slice/FrontendApplySlice";
import ShareAndCollableSliceReducer from "./slice/ShareAndCollableSlice";
import restoreReducer from "./slice/restoreSlice";
import utilsReducer from "./slice/utilsSlice";
import OperationsCountHandlerReducer from "./slice/OperationsCountHandler";
import flagListreducer from "./slice/Flagslice";
import PropsSliceReducer from "./slice/PropsSliceForApplyOperation";
import ShowProjectShareModalReducer from "./slice/ShowProjectShareModal";
import SfxModalReducer from "./slice/SfxModalSlice.js";
import SfxDurationReducer from "./slice/SfxDurationSlice.js";
import RestoreAnnotationofCutSliceReduecr from "./slice/RestoreAnnotationofCutSlice.js";
import SignInUserSlice from "./slice/SignInUserSlice.js";
import SfxDragAndDropSlice from "./slice/SfxDragAndDropSlice.js";
import koolioSpaceSliceReducer from "./slice/KoolioSpaceTourSlice.js";
import editFeaturesSliceReducer from "./slice/EditFeaturesTourSlice.js";
import moreOptionsSliceReducer from "./slice/MoreOptionsTourSlice.js";
import transcriptDrivenSliceReducer from "./slice/TranscriptDrivenTourSlice.js";
import workSpaceSliceReducer from "./slice/WorkSpaceTourSlice.js";
import AnnotationListSlice from "./slice/AnnotationListSlice.js";
import PlaylistLoaderSliceReducer from "./slice/PlaylistLoaderSlice.js";
import NestedImportSliceReducer from "./slice/NestedImportSlice.js";
import ToggleSliceReducer from "./slice/ToggleButtonForEnablingOperationsSlice.js";
import customGenaiSliceReducer from "./slice/GenaiSelectedSfxMusicSlice.js";
import speakerBtnClickedSliceReducer from "./slice/RightCLickOnSpeakerSlice.js";
import activeSpeakerSliceReducer from "./slice/ActiveSpeakerSlice.js";
import chatBotValueSliceReducer  from "./slice/FetchDataForChatBot.js";
const store = configureStore({
  reducer: {
    file: recordSliceReducer,
    uploadedFile: fileSelectedForWorkspaceSlice,
    selectedRegion: clickedRegionReducer,
    projectData: projectDataSliceReducer,
    recordBtnTrigger: recordspaceBtnTriggerSliceReducer, // this is created so that opacity of wrapper can be reduced when we cllick the recording btn from workspace
    genAiWebsocketData: genAISliceReducer,
    multiSelectedWords: multiselectSliceReducer,
    multiSelectwordObject: multiSelectWordSliceReducer,
    operationListData: operationListSliceReducer,
    copyBuffer: copyBuffer,
    closeExportToPodcastApiSlice: closeExportToPodcastApiSliceReducer,
    initiatePopUpForEpisodeSlice: initiatePopUpForEpisodeInfoSliceReducer,
    exportOptionSlice: ExportOptionsSliceReducer,
    selectedApiProvider: SelectedApiProviderSliceReducer,
    notificationDetails: NotificationsDetailsSliceReducer,
    notificationUpdateModalInfo: NotificationUpdateModalSliceReducer,
    screenReloads: ScreenReloadSliceReducer,
    viewEditMode: ViewEditModeSliceReducer,
    wavesurferObj: wavesurferobjsliceReducer,
    wavesurferObjSFX: wavesurferobjSFXsliceReducer,
    wavesurferObjGlobal: wavesurferobjGlobalsliceReducer,
    projectTitle: ProjectTitleSliceReducer,
    copiedAnnotation: copiedAnnotationReducer,
    undoRedoSlice: UndoRedoSliceReducer,
    uploadProgressStatus: uploadprogreestatusreducer,
    frontendApplyData: frontendApplySliceReducer,
    shareAndCollab: ShareAndCollableSliceReducer,
    restoreData: restoreReducer,
    utilsData: utilsReducer,
    operationCountData: OperationsCountHandlerReducer,
    flag: flagListreducer,
    propsSlice: PropsSliceReducer,
    showProjectModal: ShowProjectShareModalReducer,
    showSfxModal: SfxModalReducer,
    sfxSlice: SfxDurationReducer,
    adjustAnnotation: RestoreAnnotationofCutSliceReduecr,
    signInUser: SignInUserSlice,
    SfxDragAndDrop: SfxDragAndDropSlice,
    annotationList: AnnotationListSlice,
    playlistLoader: PlaylistLoaderSliceReducer,
    solo: soloReducer,
    nestedFile: NestedImportSliceReducer,
    toggleSlice: ToggleSliceReducer,
    customGenaiSFXMusicSlice: customGenaiSliceReducer,
    speakerBtnClicked: speakerBtnClickedSliceReducer,
    koolioSpaceTour: koolioSpaceSliceReducer,
    moreOptionsTour: moreOptionsSliceReducer,
    transcriptDrivenTour: transcriptDrivenSliceReducer,
    editFeaturesTour: editFeaturesSliceReducer,
    workSpaceTour: workSpaceSliceReducer,
    activeSpeaker: activeSpeakerSliceReducer,
    chatBot: chatBotValueSliceReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
